@keyframes heartbit {
	0% {
		transform: scale(0);
		opacity: 0;
	}
	25% {
		transform: scale(0.1);
		opacity: 0.1;
	}
	50% {
		transform: scale(0.5);
		opacity: 0.3;
	}
	75% {
		transform: scale(0.8);
		opacity: 0.5;
	}
	to {
		transform: scale(1);
		opacity: 0;
	}
}
@-moz-keyframes heartbit {
	0% {
		-moz-transform: scale(0);
		opacity: 0;
	}
	25% {
		-moz-transform: scale(0.1);
		opacity: 0.1;
	}
	50% {
		-moz-transform: scale(0.5);
		opacity: 0.3;
	}
	75% {
		-moz-transform: scale(0.8);
		opacity: 0.5;
	}
	to {
		-moz-transform: scale(1);
		opacity: 0;
	}
}
@-webkit-keyframes heartbit {
	0% {
		-webkit-transform: scale(0);
		opacity: 0;
	}
	25% {
		-webkit-transform: scale(0.1);
		opacity: 0.1;
	}
	50% {
		-webkit-transform: scale(0.5);
		opacity: 0.3;
	}
	75% {
		-webkit-transform: scale(0.8);
		opacity: 0.5;
	}
	to {
		-webkit-transform: scale(1);
		opacity: 0;
	}
}

.unreadIndicator {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	right: 0;
	top: 0;
	transform: translate(50%, -50%);

	.unreadIndicator-heartbeat {
		animation: heartbit 1s ease-out;
		animation-iteration-count: infinite;
		z-index: 10;
		border: 5px solid #e46a76;
		border-radius: 50%;
		height: 26px;
		width: 26px;
		position: absolute;
	}
	.unreadIndicator-inner {
		border-radius: 50%;
		width: 6px;
		height: 6px;
		background: #e46a76;
	}
}
