.toolbar {
	border: none;
	background: transparent;
	border-radius: 4px;
	box-shadow: none;
	z-index: 2;
	box-sizing: border-box;
	transition: opacity 250ms;
}

.toolbar:after {
	border-color: rgba(255, 255, 255, 0);
	border-top-color: #333;
	border-width: 4px;
	margin-left: -4px;
}

.toolbar:before {
	border-color: rgba(221, 221, 221, 0);
	border-top-color: #111;
	border-width: 6px;
	margin-left: -6px;
}
