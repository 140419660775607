.buttonWrapper {
	display: inline-block;
}

.button {
	background: transparent;
	color: #ddd;
	font-size: 18px;
	border: 0;
	padding-top: 5px;
	vertical-align: bottom;
	height: 34px;
	width: 36px;
	border-radius: 4px;
}

.button svg {
	fill: #ddd;
}

.button:hover,
.button:focus {
	background: var(--tertiary);
	outline: 0; /* reset for :focus */
}

button.active {
	color: var(--accent);
}

button.active svg {
	fill: var(--accent);
}
