.link_preview {
	max-width: 500px;
	line-height: 1.25rem;

	.title {
		font-weight: 700;
		max-width: 100%;
	}

	.description_wrapper {
		display: flex;
		gap: 2rem;
		max-width: 100%;
	}

	.image {
		border-radius: 6px;
		max-height: 150px;
		max-width: 100%;
	}

	iframe {
		aspect-ratio: 16/9;
		max-height: 150px;
		max-width: 100%;
		width: auto;
	}

	.cover {
		max-width: 100px;
		object-fit: contain;
		border-radius: 6px;
	}
}
