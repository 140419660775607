@import "@shopify/polaris/build/esm/styles.css";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");
@import "./scss/chat.scss";
@import "./scss/heartbeat.scss";
@import "./scss/link_preview.scss";
// @import "./scss/popover.scss";
@import "./scss/login.scss";
@import "./scss/polaris.scss";
@import "./scss/global.scss";

.profile-icon {
	display: flex;
	height: 40px;
	width: 40px;
	position: relative;
	box-shadow: 0 0 17px 1px #00000024;
	border-radius: 50%;
	padding: 0;

	& > {
		.Polaris-Icon,
		.Polaris-Avatar,
		.Polaris-Icon svg {
			width: inherit;
			height: inherit;
			min-width: unset;
		}
	}
}

body {
	background-color: $primary !important;
	overscroll-behavior: none;
}
#root {
	height: 100%;
}
html,
body,
button {
	font-family: Roboto, sans-serif, -apple-system;
}
.ReactModal__Overlay,
.ReactModal__Content {
	background-color: transparent !important;
	border-color: transparent !important;
}

.Polaris-Modal-Dialog__Container {
	z-index: 99999 !important;
}
.Polaris-Modal-Dialog__Container.front {
	z-index: 99999999 !important;
}

@keyframes ellipsis {
	to {
		width: 20px;
	}
}

@-webkit-keyframes ellipsis {
	to {
		width: 20px;
	}
}

::-webkit-scrollbar {
	width: 8px;
}
::-webkit-scrollbar-track {
	background: transparent;
}
::-webkit-scrollbar-thumb {
	background-color: rgba(155, 155, 155, 0.5);
	border-radius: 20px;
	border: transparent;
}
.Polaris-TextField__Suffix {
	padding: 0.5em;
}
