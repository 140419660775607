$svgHoverFill: #788998;

$blue: #4991cc;
$lightblue: #6eafe4;
$green: #5ac57d;
$orange: #edab51;
$red: #ff6b6b;
$pink: #e477b9;
$yellow: #ffcb00;
$yellow2: #ffd66b;
$purple: #af78f4;
$grey: #a4a4a433;
$white: #f0f0f0;
$accent: #02f4c4;

$primary: #15182e;
$secondary: #22253b;
$tertiary: #2b2f48;
$textColor: rgb(240, 240, 240);
$borderRadius: 6px;

$pulseAnimation: pulse 3s ease-in 0s infinite;

:export {
	blue: $blue;
	lightblue: $lightblue;
	green: $green;
	accent: $accent;
	orange: $orange;
	red: $red;
	pink: $pink;
	yellow: $yellow;
	yellow2: $yellow2;
	purple: $purple;
	grey: $grey;
	white: $white;
}

body {
	--textColor: rgb(240, 240, 240);
	--textColor2: rgb(150, 150, 150);
	--primary: #15182e;
	--secondary: #22253b;
	--tertiary: #2b2f48;
	--linkColor: #4991cc;
	--link: #4991cc;
	--subdued: #72758e;
	//Polaris
	--accent: #02f4c4;
	--p-decorative-one-surface: #ffcb00;
	--p-decorative-two-surface: #ff6b6b;
	--p-decorative-three-surface: #edab51;
	--p-decorative-four-surface: #5ac57d;
	--p-decorative-five-surface: #4991cc;
	--p-decorative-six-surface: #af78f4;
	--p-surface-success: #5ac57d;
	--p-focused: #02f4c473;
	--p-interactive-critical: #ff6b6b;

	--p-surface: #2b2f48;
	--p-surface-hovered: #2b2f48;
	--p-surface-selected: #2b2f48;
	--p-surface-pressed: #2b2f48;

	--p-action-secondary-pressed: #2b2f48;

	--p-border-disabled: transparent;

	--p-border-radius-base: 2px;
	--p-text: rgb(240, 240, 240);
	--p-icon: rgb(240, 240, 240);
	--p-surface-neutral: #15182e;
	--p-icon-hovered: #ffffff;
	--p-font-size-100: 1rem;
	--p-icon-pressed: #54ffdd;
	--p-interactive-hovered: #54ffdd;
	--p-interactive: #ffffff;
	--p-action-primary: #54ffdd;

	--p-action-secondary-hovered: var(--tertiary);
	--p-color-bg: var(--secondary);
	--p-color-text: rgb(240, 240, 240);
	--p-color-bg-hover: #2b2f48;
	--p-color-bg-active: #2b2f48;
	--p-color-bg-subdued-active: #2b2f48;
	--p-color-icon-hover: #ffffff;

	--p-color-bg-strong: #2b2f48;

	.Polaris-Button--primary {
		--pc-button-color: var(--accent);
		--pc-button-text: rgb(15, 15, 15);
		--pc-button-color-hover: #54ffdd;
	}
}

@keyframes pulse {
	0% {
		filter: brightness(1);
		-webkit-filter: brightness(1);
	}
	50% {
		filter: brightness(1.25);
		-webkit-filter: brightness(1.25);
	}
	100% {
		filter: brightness(1);
		-webkit-filter: brightness(1);
	}
}
@-webkit-keyframes pulse {
	0% {
		filter: brightness(1);
		-webkit-filter: brightness(1);
	}
	50% {
		filter: brightness(1.25);
		-webkit-filter: brightness(1.25);
	}
	100% {
		filter: brightness(1);
		-webkit-filter: brightness(1);
	}
}
@-moz-keyframes pulse {
	0% {
		filter: brightness(1);
		-webkit-filter: brightness(1);
	}
	50% {
		filter: brightness(1.25);
		-webkit-filter: brightness(1.25);
	}
	100% {
		filter: brightness(1);
		-webkit-filter: brightness(1);
	}
}
