html body {
	button {
		transition: background 250ms, color 250ms;
	}

	.Polaris-Modal-Dialog {
		color: $textColor;
		--p-surface: var(--secondary);
	}

	.Polaris-Tooltip-TooltipOverlay__Content {
		max-width: 500px;
	}

	.Polaris-Button--plain {
		color: #fff;
		text-decoration: none;

		&:hover {
			color: #fff !important;
			text-decoration: none !important;
		}
	}

	.Polaris-Icon path {
		outline: none;
	}

	.Polaris-Button--plain {
		margin: 0;
	}

	.Polaris-Modal-Header {
		border-bottom: none;

		h2 {
			font-size: 22px;
			line-height: 27px;
		}
	}

	.Polaris-TextField {
		input,
		textarea {
			color: rgb(15, 15, 15);
			caret-color: rgb(15, 15, 15);
		}

		svg {
			fill: rgb(15, 15, 15);
		}

		.Polaris-TextField__Backdrop {
			background-color: #eeeeee;
		}
	}

	.CommentInputContainer .Polaris-TextField svg {
		fill: var(--textColor);
	}

	.Polaris-Popover {
		// --p-text: rgb(15, 15, 15);
		// --p-icon: rgb(15, 15, 15);
		// color: rgb(15, 15, 15);
		--p-text: var(--textColor);
		--p-icon: var(--textColor);
		color: var(--textColor);
		box-shadow: 0 0px 14px #00000036;

		// padding: var(--p-space-4);
	}
}
// .Polaris-Modal-Dialog__Modal {
//   background: var(--secondary);
// }
.Polaris-Tooltip-TooltipOverlay__Content {
	--p-surface: var(--tertiary);
}
// .Polaris-Popover__Wrapper {
// 	background-color: transparent;
// }

html body button[aria-expanded="true"].Polaris-Button {
	svg:not([filltype="pathstroke"]) {
		fill: $accent;
	}

	svg[filltype="pathstroke"] path {
		stroke: $accent;
		fill: $accent;
	}
}
.Polaris-Modal-Footer {
	border: none;
}

.Polaris-Modal-Dialog__Modal.Polaris-Modal-Dialog--sizeLarge.Polaris-Modal-Dialog--fullScreen {
	.Polaris-Modal-Section {
		height: 100%;
	}
}
.Polaris-ActionList__Item:hover {
	background: var(--secondary);
}

.popover_content_wrapper {
	// --p-interactive: var(--accent);

	.Polaris-Button__Content {
		gap: 0.5rem;
	}
}
.Polaris-Modal-CloseButton:hover {
	background: var(--primary);
}

.Polaris-Icon svg {
	width: 100%;
	height: 100%;
}
.Polaris-Tabs__Wrapper {
	border-bottom: none;
}
.Polaris-Tabs__Title {
	color: var(--textColor);
}

.Polaris-Modal-Section:not(:last-of-type) {
	border: none;
}

.Polaris-Modal-Dialog__Modal.Polaris-Modal-Dialog--fullScreen {
	min-height: 80vh;
}

.Polaris-Tabs__Tab {
	.Polaris-Tabs__Title::before {
		transition: background 250ms, background-color 250ms;
	}
}

//Topbar searchbar
.Polaris-TopBar {
	// background: transparent;
	background: var(--primary);
}

.Polaris-TopBar-SearchField__Backdrop {
	background: var(--tertiary);
}

.Polaris-Frame {
	background: transparent;
}

.Polaris-TopBar-Search__SearchContent {
	background: var(--tertiary);
}
.Polaris-Avatar__Initials {
	text-transform: uppercase;
}

.Polaris-TopBar-Search__Results {
	.Polaris-ActionList__Actions {
		border-bottom-color: var(--secondary);
	}
}

.Polaris-TopBar-Menu__Activator:hover {
	background-color: var(--secondary);
}

.Polaris-TopBar__SearchField {
	// margin-left: calc(300px + 170px);
	margin-left: 300px;
	display: flex;
	justify-content: center;
	transition: margin 500ms;

	@media (max-width: 1200px) {
		margin-left: 0px;
	}
}

.Polaris-TopBar-SearchField,
.Polaris-TopBar-Search,
.Polaris-TopBar-Menu__ActivatorWrapper {
	-webkit-app-region: none;
}

.Polaris-TopBar-Search {
	margin: auto;
}

body[platform="Windows"] {
	#AppFrameTopBar {
		padding-right: 150px;
	}
}

#AppFrameTopBar {
	-webkit-app-region: drag;
}

.Polaris-TopBar,
.Polaris-Frame__TopBar {
	height: 50px;
}

// Prevents modals from overlapping with the toolbar and hiding the modals close button
html body .Polaris-Modal-Dialog__Container {
	top: 50px;
	bottom: 50px;
}

body[data-lock-scrolling] {
	overflow-y: unset;
}

// .Polaris-Popover__Wrapper {
// 	padding: 1rem;
// }
// .Polaris-Popover__Wrapper {
// 	background-color: var(--tertiary);
// 	color: var(--textColor);

// 	.Polaris-Icon svg {
// 		fill: var(--textColor);
// 	}
// }

html body {
	--accent: #02f4c4;
	--p-decorative-one-surface: #ffcb00;
	--p-decorative-two-surface: #ff6b6b;
	--p-decorative-three-surface: #edab51;
	--p-decorative-four-surface: #5ac57d;
	--p-decorative-five-surface: #4991cc;
	--p-decorative-six-surface: #af78f4;

	--p-text-critical: #ff6b6b;
	--p-icon-critical: #ff6b6b;
	--p-action-critical: #ff6b6b;
	--p-action-critical-depressed: #ff6b6b;
	--p-surface-success: #5ac57d;
}
