@import "./variables.scss";
$sidebarTopSectionHeight: 180px;
$chatlistWidth: 300px;

.mainDashboard.chats {
	height: 100%;
}

.chats-list,
.chat-window,
.chat-view {
	button {
		transition: filter 250ms;
		cursor: pointer;

		&:hover {
			filter: brightness(1.15);
		}
	}

	.profile {
		object-fit: cover;
	}

	.Polaris-TextField,
	.Polaris-TextField__Input {
		border-radius: 1rem;
		margin: 0;
	}

	.Polaris-TextField__Backdrop {
		box-shadow: none !important;
		display: none;
		&:after {
			opacity: 0;
		}
	}
}

.chat-view {
	height: 100vh;
	width: 100vw;
	// display: flex;
	// flex-direction: row;
	// gap: 2rem;
	min-height: 400px;
	background: $primary;
	transition: grid-template-columns 350ms;

	display: grid;

	grid-template-areas:
		"toolbar toolbar"
		"list header"
		"list messages"
		"list input";
	grid-template-columns: 300px auto;

	grid-template-rows: 45px 75px auto fit-content(50px);

	color: $textColor;

	.chats-list {
		grid-area: list;
		transition: width 350ms;
	}

	.ChatWindowHeader {
		grid-area: header;
	}

	.mainWindow {
		grid-area: messages;
		overflow: auto;
		height: 100%;
		display: grid;
		align-items: end;

		// .messages {
		// 	display: grid;
		// }
		// padding-top: 1rem;
	}

	.CommentInputContainer {
		grid-area: input;
	}

	@media (max-width: 450px) {
		grid-template-columns: 0px auto;
		.chats-list {
			overflow: hidden;
			width: 0px;
			padding: 0;
			min-width: 0px;
		}
	}
}

.no-selected-chat {
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 1 1 auto;
	flex-direction: column;
	gap: 1rem;

	.buttons-wrapper {
		display: flex;
		flex-direction: column;
		gap: 1rem;
	}

	button {
		background-color: $secondary;
		border-radius: 1rem;
		padding: 2rem 3rem;
		font-size: 1.8rem;
		display: flex;
		border: none;
		color: $textColor;
		cursor: pointer;
		transition: filter 250ms;
		justify-content: space-between;

		&:hover {
			filter: brightness(1.1);
		}

		.Polaris-Icon {
			margin: 0;
			margin-left: 2rem;

			svg {
				fill: $green;
			}
		}
	}

	h1 {
		font-size: 3rem;
		line-height: 3rem;
		margin-bottom: 3rem;
	}
}

.new-chat .no-selected-chat button {
	background-color: $secondary;
}

.notifications-list {
	overflow: auto;
	flex: 1;
}

.CommentInputContainer {
	align-items: center;
	display: flex;
	justify-content: space-between;
	width: 100%;
	background: $primary;
	padding: 0.75rem;
	border-radius: $borderRadius;
	gap: 0.75rem;
	position: relative;
	box-shadow: 0px -10px 25px #00000069;
	z-index: 1;
	border-top: 1px solid rgba(255, 255, 255, 0.1);

	.Polaris-TextField textarea.Polaris-TextField__Input {
		caret-color: $textColor;
		max-height: 500px;
		margin-bottom: 1rem;
	}

	.Polaris-TextField__Suffix {
		width: 100%;
	}

	// max-height: 50%;
	// overflow: auto;

	// &::before {
	// 	content: "";
	// 	position: absolute;
	// 	top: 0;
	// 	left: 0px;
	// 	right: 0px;
	// 	height: 1px;
	// 	background-color: rgba(255, 255, 255, 0.1);
	// }

	svg {
		fill: var(--textColor);
	}

	.Polaris-TextField__Suffix {
		padding: 0;
		position: absolute;
		right: 0;
		bottom: 0;

		.Polaris-Button--plain {
			margin: 0;
		}
	}

	.profile {
		width: 30px;
		height: 30px;
		border-radius: 50%;
	}

	.Polaris-TextField__Input {
		border: none;
		background: transparent;
		margin: 0;
		border-color: #ffffff1a;
		border-radius: 0;
		border-width: 2px;
		border-right-style: solid;
		border-left-style: solid;
		color: $textColor;
	}

	.Polaris-TextField {
		background: none;
	}

	.Polaris-TextField__Backdrop {
		display: none;
	}

	.Polaris-TextField__Suffix {
		margin-right: 0;
	}

	.Polaris-Stack {
		align-items: center;
	}
}

.messages {
	background: var(--primary);
	// max-height: 100%;
	// overflow-y: scroll;
	padding: 0 1.6rem;
	padding-bottom: 1rem;
	// justify-content: end;
	display: flex;
	height: 100%;
	flex: 1;
	grid-area: messages;
	position: relative;

	&.skeleton {
		justify-content: end;
	}

	.chat-window & {
		background: transparent !important;
	}

	.message {
		margin-top: 15px;
		display: flex;
		flex-direction: column;

		.media {
			display: flex;
			gap: 1rem;
		}

		&.skeleton {
			div {
				background: $secondary !important;
				border-radius: $borderRadius;
				animation: $pulseAnimation;
			}

			.name {
				height: 0.8rem;
				width: 20%;
				margin-bottom: 5px;
			}
			.row {
				display: flex;
				align-items: center;
				gap: 15px;
				width: 100%;

				.text {
					height: 3rem;
					min-width: 30%;
				}
				.profile {
					min-width: 40px;
					height: 40px;
					border-radius: 0.35rem;
				}
			}
		}

		.first-row,
		.name-date {
			width: max-content;
		}

		.first-row {
			display: flex;
			gap: 15px;
			max-width: 60%;

			.Polaris-Avatar {
				height: max-content;
			}

			.profile {
				width: 40px;
				height: 40px;
				border-radius: 0.35rem;
			}

			.text {
				// background: $secondary;
				padding: 1rem;
			}
		}
		&.mention .text {
			background: #948313;
			color: #ffffff;
		}

		.name-date {
			text-align: right;
			color: rgba($textColor, 0.5);
			font-size: 0.95rem;
			margin-bottom: 5px;
			position: relative;

			.Polaris-Spinner {
				position: absolute;
				transform: translateX(-115%);
				opacity: 0.75;
			}

			.error {
				color: $red;
				font-weight: bold;
				font-size: 2.5rem;
				line-height: 2.5rem;
				position: absolute;
				transform: translateX(-200%);
			}
		}

		&.other {
			align-items: start;

			.text {
				border-radius: 5px 15px 15px 15px;
				color: $textColor;
			}
		}

		&.system {
			.text {
				border-radius: 15px 15px 15px 15px;
				color: rgba($textColor, 0.5);
			}
		}
	}
}

.select-new-chat {
	width: 100%;
}

.chat-window .header {
	justify-content: space-between;

	.header-left {
		display: flex;
		gap: 1rem;
		align-items: center;
	}
}

.chat-window .header,
.new-chat .header,
.select-new-chat .header {
	display: flex;
	background: $secondary;
	border-radius: $borderRadius;
	padding: 1rem;
	gap: 1rem;

	.group-members {
		flex: 1 1 auto;
		overflow: hidden;

		.title {
			font-weight: 600;
			font-size: 1.5rem;
			line-height: 1.5rem;
			margin-bottom: 3px;
		}

		.members {
			overflow-x: scroll;
			display: flex;
			height: 22px;
			gap: 0.5rem;

			.profile,
			.Polaris-Avatar {
				width: 22px;
				min-width: 22px;
				height: 22px;
				min-height: 22px;
				border-radius: 50%;
			}
		}
	}

	.chat-title {
		align-self: center;
		margin-right: 3rem;

		color: $textColor;
		font-weight: 600;
		font-size: 2rem;
		line-height: 2rem;
	}

	.actions {
		display: flex;
		gap: 1rem;
	}

	.actions button,
	& button {
		padding: 1rem;
		background: $secondary;
		border-radius: 0.5rem;

		&.Polaris-Button--plain {
			margin: 0 !important;
			color: transparent;
		}

		.Polaris-Button__Icon {
			display: flex;
		}

		.Polaris-Icon {
			svg,
			img {
				height: 24px;
				width: 24px;
				fill: $green;
			}
		}
	}
}

.confirm-leave-chat {
	display: flex;
	gap: 1rem;
	flex-direction: column;

	.buttons-wrapper {
		display: flex;
		justify-content: space-between;
	}
}

.chats-list {
	max-height: 100%;
	width: $chatlistWidth;
	min-width: $chatlistWidth;
	background: $secondary;
	overflow: hidden;
	border-radius: $borderRadius;
	display: flex;
	flex-direction: column;
	position: relative;
	padding: 1rem;

	a {
		text-decoration: none;
	}

	.Polaris-Sheet & {
		width: 100%;
	}

	.SkeletonLoader {
		padding: 1rem;
		animation: $pulseAnimation;
		direction: ltr;

		.profile {
			background: $primary;
		}
		.name {
			background: $primary;
			height: 20px;
			border-radius: 7px;
			width: 50%;
			margin-bottom: 7px;
		}
	}

	.Polaris-Connected {
		margin: 1em 0rem;

		.add-new-chat {
			padding: 1rem;
			border-radius: $borderRadius !important;
			border: none;

			.Polaris-Icon {
				height: 20px;
				width: 20px;

				svg {
					fill: rgba($green, 0.85);
				}
			}
		}
	}

	.chat-list-item,
	.SkeletonLoader {
		display: flex;
		flex-direction: row;
		width: 100%;
		// justify-content: space-between;
		align-items: center;
		// padding-left: 2rem;

		.number {
			height: fit-content;
		}

		.profile,
		.Polaris-Avatar {
			height: 30px;
			width: 30px;
			min-width: 30px;
			border-radius: 50%;
		}

		.Profile-Avatar {
			margin-right: 1rem;
			cursor: pointer;
		}

		.name {
			// flex-grow: 1;
			position: relative;
			font-weight: 700;
			font-size: 1em;
		}
	}

	.List {
		display: flex;
		flex-direction: column;

		.Profile-Avatar {
			padding-left: 0;
		}
	}
}

.members-list-popup {
	display: flex;
	flex-direction: column;
	padding: 1rem;
	gap: 1rem;
	// position: absolute;
	// right: 0;
	// top: 0;
	// transform: translateX(100%);
	pointer-events: none;
	opacity: 0;
	transition: opacity 500ms 500ms;
	background-color: $primary;
	border-radius: $borderRadius;

	.member {
		display: flex;
		align-items: center;
	}

	.profile {
		width: 20px;
		height: 20px;
		border-radius: 50%;
		margin-right: 3px;
	}

	.name {
		font-weight: 600;
	}
}
.newchat-wrapper {
	width: 100%;
	padding: 1rem;
}

.list-section-header {
	display: flex;
	cursor: pointer;
	justify-content: space-between;
	padding: 1rem 0;
	color: $textColor;

	h2 {
		font-size: 1.6rem;
		font-weight: 600;
	}

	.open-newchat-button .Polaris-Icon svg {
		fill: rgb(200, 200, 200) !important;

		&:hover {
			fill: $textColor !important;
		}
	}

	.open-icon {
		margin-left: 1rem;

		.Polaris-Icon {
			margin: 0;
			height: 12px;
			width: 12px;
			transform: rotate(180deg);
			transition: transform 250ms;
			fill: $white;
		}
	}

	&[aria-expanded="true"] {
		color: $green;
		.open-icon .Polaris-Icon {
			transform: rotate(270deg);
			fill: $green;
		}
	}
}

.chat-list-wrapper {
	overflow-y: auto;
	height: calc(100% - #{$sidebarTopSectionHeight});
}

.chat-member-avatar {
	cursor: pointer;
	// float: left;
	position: absolute;
	left: 0;
	// margin-right: 1rem;

	.Polaris-Avatar {
		width: 40px;
		height: 40px;
	}
}
.chat-member-info-wrapper {
	padding: 1rem;

	.header {
		display: flex;
		gap: 1rem;

		.name {
			font-weight: 600;
		}
	}
}

.chat-list-item {
	position: relative;
	transition: filter 250ms, background 250ms;
	cursor: pointer;
	background: rgba($green, 0);
	padding: 0.25rem;
	color: rgba($textColor, 0.5);

	display: flex;
	flex-direction: row;
	width: 100%;
	// justify-content: space-between;
	align-items: center;
	// padding-left: 2rem;

	.number {
		height: fit-content;
	}

	.profile,
	.Polaris-Avatar {
		height: 30px;
		width: 30px;
		min-width: 30px;
		border-radius: 50%;
	}

	.Profile-Avatar {
		margin-right: 1rem;
	}

	.name {
		text-overflow: ellipsis;
		overflow: hidden;
		-webkit-line-clamp: 1;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		word-break: break-all;
		transition: opacity 150ms;

		position: relative;
		font-weight: 700;
		font-size: 1em;
	}

	&.active {
		color: $textColor;
	}

	.text {
		width: calc(#{$chatlistWidth} - (60px + 1rem));
	}

	.number {
		position: absolute;
		right: 10px;
		transition: right 250ms;
	}

	&.unread {
		&::after {
			content: attr(data-unreadcount);
			position: absolute;
			background: #02f4c4;
			right: 0;
			color: black;
			padding: 1px 5px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-weight: bold;
			border-radius: 20px;
			transition: right 350ms, transform 350ms;
		}
	}

	&.group,
	&.new {
		.Polaris-Avatar {
			background-color: rgba(#ffffff, 0.1);

			text {
				color: rgba($textColor, 0.5);
			}
		}
	}

	.leave-chat-button {
		position: absolute;
		right: 10px;
		pointer-events: none;
		opacity: 0;
		transition: opacity 250ms, scale 250ms;
		display: flex;
		top: 0;
		height: 100%;

		.Polaris-Button.Polaris-Button--plain {
			color: transparent;
		}

		.Polaris-Icon {
			width: 1rem;
			height: 1rem;
		}

		&:hover {
			opacity: 1;
			scale: 1.1;

			.Polaris-Icon__Svg {
				fill: $red;
			}
		}
	}

	&:hover {
		color: $textColor;
		z-index: 1;
		background-color: $tertiary;

		&::after {
			right: 32px;
		}
	}

	&:hover,
	&.leaving {
		.leave-chat-button {
			opacity: 0.4;
			pointer-events: all;
		}
	}
}

.commentInput-suffix {
	display: flex;
	align-items: center;
	// gap: 1rem;
	justify-content: space-between;
	width: 100%;

	.Polaris-Button:not(.Polaris-Button--primary):not(.Polaris-Button--plain) {
		background: var(--secondary);
	}
}
.suffixes_container {
	display: flex;
	gap: 1rem;
	align-items: center;

	.Polaris-Icon {
		height: 20px;
		width: 20px;
	}

	.Polaris-Button--sizeSlim {
		min-height: unset;
	}
}

.attachments_container {
	display: flex;
	gap: 2rem;
	flex-wrap: wrap;
	margin-top: 2rem;
}

.member_avatar {
	.removeButton {
		transition: opacity 250ms;
		opacity: 0;
	}
	&:hover {
		.removeButton {
			opacity: 1;
		}
	}
}

// [data-test-id="virtuoso-top-item-list"] {
// 	.date-line {
// 		.line {
// 			background: transparent;
// 		}
// 	}
// }

.activator {
	cursor: pointer;
	&:hover {
		text-decoration: underline;
	}
}

.Profile-Avatar,
.profile-icon {
	background: transparent;
	border: none;
	color: var(--textColor);
}
