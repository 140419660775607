@import "../../../scss/variables.scss";

.mention {
	color: $yellow;
	text-decoration: none;
	position: relative;
	padding: 3px 5px;
	border-radius: 5px;

	&::after {
		content: "";
		position: absolute;
		inset: 0;
		background-color: $yellow;
		opacity: 0.25;
		border-radius: 5px;
	}
}

.mentionSuggestions {
	background: var(--tertiary);
	border-radius: 7px;
	cursor: pointer;
	padding-top: 8px;
	padding-bottom: 8px;
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	//transform-origin: 50% 0%;
	//transform: scaleY(0);
	margin-block: 5px;
	z-index: 2;
}

.wrapper {
	display: table;
	width: 100%;

	.profile {
		height: 20px;
		width: 20px;
		border-radius: 50%;
		/* margin-right: 5px; */
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.profile-name {
		display: flex;
	}
	.online-dot {
		position: relative;
	}
}

.wrapperLeft,
.wrapperRight {
	display: table-cell;
	vertical-align: middle;
}

.wrapperRight {
	width: 100%;
	padding-left: 8px;
}

.mentionSuggestionsEntry {
	padding: 7px 10px 3px 10px;
	transition: background-color 0.4s cubic-bezier(0.27, 1.27, 0.48, 0.56);
	display: flex;
	gap: 0.5rem;
}

.mentionSuggestionsEntry:active {
	background-color: var(--tertiary);
}

.mentionSuggestionsEntryFocused {
	composes: mentionSuggestionsEntry;
	background-color: var(--tertiary);
}

.mentionName {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.mentionAvatar {
	display: block;
	width: 30px;
	height: 30px;
	border-radius: 50%;
}
