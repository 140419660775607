/* width */
::-webkit-scrollbar {
	width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555;
}

html {
	background: var(--primary);
}

.oneline,
.one-line {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.hideScrollbar,
.hideScroll,
.noScrollbar,
.noScroll,
.noscroll {
	-ms-overflow-style: none;
	scrollbar-width: none;

	&::-webkit-scrollbar {
		display: none;
	}
}

.capitalize {
	text-transform: capitalize;
}

.loading-text:after {
	overflow: hidden;
	display: inline-block;
	vertical-align: bottom;
	animation: ellipsis steps(3, end) 1800ms infinite;
	-webkit-animation: ellipsis steps(3, end) 1800ms infinite;
	content: "\2026";
	width: 0px;
}

.loading {
	animation: $pulseAnimation;
}

em-emoji-picker {
	--background-rgb: 44, 48, 73;
	--font-family: Roboto, sans-serif, -apple-system;
	--rgb-background: 43, 47, 72;
	// --border-radius: 24px;
	// --category-icon-size: 24px;
	// --color-border-over: rgba(0, 0, 0, 0.1);
	// --color-border: rgba(0, 0, 0, 0.05);
	// --font-size: 20px;
	--rgb-accent: 2, 244, 196;
	// --rgb-color: 102, 51, 153;
	// --rgb-input: 255, 235, 235;
	// --shadow: 5px 5px 15px -8px rebeccapurple;
}
